<!--
 * @Description: 用户审核--用户实名审核-待审核列表页面
 * @Author: 小广
 * @Date: 2019-05-29 18:33:57
 * @LastEditors  : 蒙川
 * @LastEditTime : 2020-01-13 14:26:58
 -->
<template>
  <div class="realNameAuditList-wrapper">
    <list-item :status="0"></list-item>
  </div>
</template>

<script>
import ListItem from './RealNameAuditListItem.vue'

export default {
  name: '',
  components: {
    'list-item': ListItem
  },
  data () {
    return {}
  },

  methods: {}
}
</script>

<style scoped lang="scss">
.realNameAuditList-wrapper {
  height: 100%;
}
</style>
